import { ConsultationsApiClientInterface } from './ConsultationsApiClient.interface'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import { ConsultationInterface } from '@/models/resources/consultation.interface'
import { CallSmallInterface } from '@/models/resources/call.interface'
import { TaskInterface } from '@/models/resources/task.interface'
import {VideoCallSmallInterface} from "@/models/resources/videoCall.interface";

/**
 * @Name ConsultationsApiClientModel
 * @description
 * Implements the ConsultationsApiClientInterface interface
 */
export class ConsultationsApiClientModel implements ConsultationsApiClientInterface {
  fetchConsultation(consultationId: string): Promise<ConsultationInterface> {
    const parameters: HttpRequestParamsInterface = {
      url: `/consultations/${consultationId}`,
      requiresToken: true,
    }

    return HttpClient.get<ConsultationInterface>(parameters)
  }

  fetchCalls(consultationId: string): Promise<CallSmallInterface[]> {
    const parameters: HttpRequestParamsInterface = {
      url: `/consultations/${consultationId}/calls`,
      requiresToken: true,
    }

    return HttpClient.get<CallSmallInterface[]>(parameters)
  }

  fetchVideoCalls(consultationId: string): Promise<VideoCallSmallInterface[]> {
    const parameters: HttpRequestParamsInterface = {
      url: `/consultations/${consultationId}/video`,
      requiresToken: true,
    }

    return HttpClient.get<VideoCallSmallInterface[]>(parameters)
  }

  updateCallStatus(consultationId: string, callSid: string, status: string): Promise<CallSmallInterface[]> {
    const parameters: HttpRequestParamsInterface = {
      url: `/consultations/${consultationId}/calls/${callSid}`,
      requiresToken: true,
      payload: { status: status },
    }

    return HttpClient.patch<CallSmallInterface[]>(parameters)
  }

  updateVideoStatus (consultationId: string, status: string): Promise<void> {
    const parameters: HttpRequestParamsInterface = {
      url: `/consultations/${consultationId}/video`,
      requiresToken: true,
      payload: { status },
    }

    return HttpClient.patch<void>(parameters)
  }

  storeTask(consultationId: string, task: TaskInterface): Promise<TaskInterface> {
    const parameters: HttpRequestParamsInterface = {
      url: `/consultations/${consultationId}/tasks`,
      requiresToken: true,
      payload: task,
    }

    return HttpClient.post<TaskInterface>(parameters)
  }
}
