import { defineStore } from 'pinia'
import apiClient from '@/api-client'
import { ConsultationInterface} from '@/models/resources/consultation.interface'
import { ref } from 'vue'
import { CallSmallInterface } from '@/models/resources/call.interface'
import { TaskInterface } from '@/models/resources/task.interface'
import {VideoCallSmallInterface} from "@/models/resources/videoCall.interface";

export const useConsultationsStore = defineStore('consultations', () => {
  const consultation = ref<ConsultationInterface>()
  const calls = ref<CallSmallInterface[]>()
  const videoCalls = ref<VideoCallSmallInterface[]>()
  const task = ref<TaskInterface>()

  async function fetchConsultation(consultationId: string) {
    try {
      consultation.value = await apiClient.consultations.fetchConsultation(consultationId)
    }
    catch (error) {
      return error
    }
  }

  async function fetchCalls(consultationId: string) {
    try {
      calls.value = await apiClient.consultations.fetchCalls(consultationId)
    }
    catch (error) {
      return error
    }
  }

  async function fetchVideoCalls(consultationId: string) {
    try {
      videoCalls.value = await apiClient.consultations.fetchVideoCalls(consultationId)
    }
    catch (error) {
      return error
    }
  }

  async function updateCallStatus(consultationId: string, callSid: string, status: string) {
    try {
      calls.value = await apiClient.consultations.updateCallStatus(consultationId, callSid, status)
    }
    catch (error) {
      return error
    }
  }

  async function updateVideoStatus(success: boolean) {
    const status = success ? 'completed' : 'unfruitful';
    const consultationId = consultation.value?.id

    if (!consultationId) {
      throw new Error('updateVideoStatus: missing consultation.')
    }

    try {
      await apiClient.consultations.updateVideoStatus(consultationId, status)
    }
    catch (error) {
      return error
    }
  }

  async function storeTask(consultationId: string, taskData: TaskInterface) {
    try {
      task.value = await apiClient.consultations.storeTask(consultationId, taskData)
    }
    catch (error) {
      return error
    }
  }

  return {
    // state
    consultation,
    calls,
    videoCalls,
    task,
    // actions
    fetchConsultation,
    updateCallStatus,
    updateVideoStatus,
    fetchCalls,
    fetchVideoCalls,
    storeTask,
  }
})
