
import { TwilioApiClientInterface } from './TwilioApiClient.interface'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

/**
 * @Name TaxonomiesApiClientModel
 * @description
 * Implements the TaxonomiesApiClientInterface interface
 */
export class TwilioApiClientModel implements TwilioApiClientInterface {
  fetchVoiceToken(): Promise<unknown> {
    const getParameters: HttpRequestParamsInterface = {
      url: '/twilio/voice/token',
      requiresToken: true,
    }

    return HttpClient.post<unknown>(getParameters)
  }

  fetchVideoToken(consultationId: string): Promise<unknown> {
    const getParameters: HttpRequestParamsInterface = {
      url: `/consultations/${consultationId}/video/generate-room-token`,
      requiresToken: true
    }

    return HttpClient.post<any>(getParameters)
        .then(response => {
          return response;
        });
  }

  createCall(consultationId: string, roomId: string): Promise<unknown> {
    const getParameters: HttpRequestParamsInterface = {
      url: `/twilio/video/request`,
      requiresToken: true,
      payload: {
        callableId: consultationId,
        callableType: 'consultation',
        roomSid: roomId
      }
    }

    return HttpClient.post<unknown>(getParameters)
  }

}
