/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { HttpRequestParamsInterface } from './HttpRequestParams.interface'
import { HttpClientInterface } from './HttpClient.interface'

/**
 * @name HttpClientModel
 * @description
 * Wraps http client functionality to avoid directly using a third party npm package like axios
 * and simplify replacement in the future if such npm package would stop being developed or other reasons
 */
export class HttpClientModel implements HttpClientInterface {
  private getToken(): string {
    return localStorage.getItem('authToken') || ''
  }

  constructor() {
    // OPTIONAL for now: Add request interceptor to handle errors or other things for each request in one place
  }

  get<T>(parameters: HttpRequestParamsInterface): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, requiresToken, payload } = parameters

      // axios options
      const options: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_API_URL,
        headers: {},
      }

      if (requiresToken) {
        const token = this.getToken()
        options.headers!.Authorization = `Bearer ${token}`
      }

      if (payload)
        options.params = payload

      axios
        .get(url, options)
        .then((response: AxiosResponse) => {
          resolve(response.data.data as T)
        })
        .catch((error: AxiosResponse) => {
          console.info('------ rejecting ----')
          reject(error)
        })
    })
  }

  post<T>(parameters: HttpRequestParamsInterface): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, requiresToken, payload, config = null } = parameters

      // axios options
      const options: AxiosRequestConfig = {
        ...config,
        ...{
          baseURL: process.env.VUE_APP_API_URL,
          headers: {},
        },
      }

      if (requiresToken) {
        const token = this.getToken()
        options.headers!.Authorization = `Bearer ${token}`
      }

      axios
        .post(url, payload, options)
        .then((response: AxiosResponse) => {
          resolve(response.data.data as T)
        })
        .catch((error: AxiosResponse) => {
          reject(error)
        })
    })
  }

  put<T>(parameters: HttpRequestParamsInterface): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, requiresToken, payload } = parameters

      // axios options
      const options: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_API_URL,
        headers: {},
      }

      if (requiresToken) {
        const token = this.getToken()
        options.headers!.Authorization = `Bearer ${token}`
      }

      axios
        .put(url, payload, options)
        .then((response: AxiosResponse) => {
          resolve(response.data.data as T)
        })
        .catch((error: AxiosResponse) => {
          console.info('------ rejecting ----')
          reject(error)
        })
    })
  }

  patch<T>(parameters: HttpRequestParamsInterface): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, requiresToken, payload } = parameters

      // axios options
      const options: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_API_URL,
        headers: {},
      }

      if (requiresToken) {
        const token = this.getToken()
        options.headers!.Authorization = `Bearer ${token}`
      }

      axios
        .patch(url, payload, options)
        .then((response: AxiosResponse) => {
          resolve(response.data.data as T)
        })
        .catch((error: AxiosResponse) => {
          console.info('------ rejecting ----')
          reject(error)
        })
    })
  }

  delete<T>(parameters: HttpRequestParamsInterface): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, requiresToken } = parameters

      const options: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_API_URL,
        headers: {},
      }

      if (requiresToken) {
        const token = this.getToken()
        options.headers!.Authorization = `Bearer ${token}`
      }

      axios
        .delete(url, options)
        .then((response: AxiosResponse) => {
          resolve(response.data.data as T)
        })
        .catch((error: AxiosResponse) => {
          console.info('------ rejecting ----')
          reject(error)
        })
    })
  }

  blob<T>(parameters: HttpRequestParamsInterface): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, requiresToken } = parameters

      // axios options
      const options: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_API_URL,
        headers: {},
      }

      if (requiresToken) {
        const token = this.getToken()
        options.headers!.Authorization = `Bearer ${token}`
      }

      axios
        .get(url, options)
        .then((response: AxiosResponse) => {
          resolve(response.data as T)
        })
        .catch((error: AxiosResponse) => {
          console.info('------ rejecting ----')
          reject(error)
        })
    })
  }

  pdf<T>(parameters: HttpRequestParamsInterface): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, requiresToken } = parameters

      const options: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_API_URL,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf',
        },
      }

      if (requiresToken) {
        const token = this.getToken()
        options.headers!.Authorization = `Bearer ${token}`
      }

      axios
        .get(url, options)
        .then((response: AxiosResponse) => {
          resolve(response.data as T)
        })
        .catch((error: AxiosResponse) => {
          console.info('------ rejecting ----')
          reject(error)
        })
    })
  }
}
