import consultationsApiClient from './consultations'
import taxonomiesApiClient from './taxonomies'
import twilioApiClient from './twilio'
import { ApiClientInterface } from '@/models/api-client/ApiClient.interface'
import professionalsApiClient from '@/api-client/live/professionals'

// create an instance of our main ApiClient that wraps the live child clients
const apiLiveClient: ApiClientInterface = {
  professionals: professionalsApiClient,
  consultations: consultationsApiClient,
  taxonomies: taxonomiesApiClient,
  twilio: twilioApiClient,
}
// export our instance
export default apiLiveClient
