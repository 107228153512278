import { defineStore } from 'pinia'
import apiClient from '@/api-client'
import { ConsultationInterface} from '@/models/resources/consultation.interface'
import { ref } from 'vue'
import { CallSmallInterface } from '@/models/resources/call.interface'
import { TaskInterface } from '@/models/resources/task.interface'
import {VideoCallSmallInterface} from "@/models/resources/videoCall.interface";
import { CalledProfessionalInterface } from '@/models/resources/professional.interface'

export const useProfessionalsStore = defineStore('professionals', () => {

  const professional = ref<CalledProfessionalInterface | undefined>()

  async function fetchProfessional(professionalId: string) {
    try {
      professional.value = await apiClient.professionals.fetchProfessional(professionalId);
      console.log("PROFESSIONAL FOUND:")
      console.log(professional.value)
    }
    catch (error) {
      return error
    }
  }


  return {
    // state
    professional,
    // actions
    fetchProfessional,
  }
})
