// import apiMockClient from './mock'
import apiLiveClient from './live'
import { ApiClientInterface } from '@/models/api-client/ApiClient.interface'

// return either the live or the mock client
let apiClient: ApiClientInterface
// if (config.apiClient.type === 'live')
// eslint-disable-next-line prefer-const
apiClient = apiLiveClient

// else if (config.apiClient.type === 'mock')
//   apiClient = apiMockClient

// else
//   throw new Error('Invalid or undefined config.apiClient.type')

export default apiClient
