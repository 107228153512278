import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import { variantJS } from '@variantjs/vue'
import variantConfiguration from './modules/variant'
import './assets/tailwind.css'
import router from './router'
import i18n from './i18n'

createApp(App).use(i18n).use(router)
    .use(createPinia())
    .use(variantJS, variantConfiguration)
    .mount('#app')
