import { ProfessionalsApiClientInterface } from './ProfessionalsApiClient.interface'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import { CalledProfessionalInterface } from '@/models/resources/professional.interface'

/**
 * @Name ProfessionalsApiClientModel
 * @description
 * Implements the ProfessionalsApiClientInterface interface
 */
export class ProfessionalsApiClientModel implements ProfessionalsApiClientInterface {
  fetchProfessional(professionalId: string): Promise<CalledProfessionalInterface> {
    const parameters: HttpRequestParamsInterface = {
      url: `/professionals/${professionalId}`,
      requiresToken: true,
    }

    console.log("PARAMETERS")
    console.log(parameters)

    return HttpClient.get<CalledProfessionalInterface>(parameters)
  }
}
