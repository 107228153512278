
import { TaxonomiesApiClientInterface } from './TaxonomiesApiClient.interface'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import { CitySmallInterface } from '@/models/resources/city.interface'
import { CountrySmallInterface } from '@/models/resources/country.interface'
import { GeneralPractitionerSmallInterface } from '@/models/resources/generalPractitioner.interface'
import { InsuranceSmallInterface } from '@/models/resources/insurance.interface'
import { OrganizationInterface } from '@/models/resources/organization.interface'

/**
 * @Name TaxonomiesApiClientModel
 * @description
 * Implements the TaxonomiesApiClientInterface interface
 */
export class TaxonomiesApiClientModel implements TaxonomiesApiClientInterface {
  fetchCities(): Promise<CitySmallInterface[]> {
    const getParameters: HttpRequestParamsInterface = {
      url: '/cities',
      requiresToken: false,
    }

    return HttpClient.get<CitySmallInterface[]>(getParameters)
  }

  fetchCountries(): Promise<CountrySmallInterface[]> {
    const getParameters: HttpRequestParamsInterface = {
      url: '/countries',
      requiresToken: false,
    }

    return HttpClient.get<CountrySmallInterface[]>(getParameters)
  }

  fetchGeneralPractitioners(payload: { q?: string | undefined }): Promise<GeneralPractitionerSmallInterface[]> {
    const getParameters: HttpRequestParamsInterface = {
      url: '/general-practitioners',
      requiresToken: true,
    }

    return HttpClient.get<GeneralPractitionerSmallInterface[]>(getParameters)
  }

  fetchInsurances(): Promise<InsuranceSmallInterface[]> {
    const getParameters: HttpRequestParamsInterface = {
      url: '/insurances/',
      requiresToken: true,
    }

    return HttpClient.get<InsuranceSmallInterface[]>(getParameters)
  }

  fetchOrganization(organizationKey: string): Promise<OrganizationInterface> {
    const getParameters: HttpRequestParamsInterface = {
      url: `/organizations/${organizationKey}`,
      requiresToken: true,
    }

    return HttpClient.get<OrganizationInterface>(getParameters)
  }
}
