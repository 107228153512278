const variantConfiguration = {
    TAlert: {
      fixedClasses: {
        wrapper: 'rounded p-4 flex text-sm border-l-4 my-3',
        body: 'flex-grow',
        close: 'ml-4 rounded',
        closeIcon: 'h-5 w-5 fill-current',
      },
      classes: {
        wrapper: 'bg-blue-100 border-blue-500',
        body: 'text-blue-700',
        close: 'text-blue-700 hover:text-blue-500 hover:bg-blue-200',
        closeIcon: 'h-5 w-5 fill-current',
      },
      variants: {
        warning: {
          classes: {
            wrapper: 'bg-yellow-100 border-yellow-500',
            body: 'text-yellow-700',
            close: 'text-yellow-700 hover:text-yellow-500 hover:bg-yellow-200',
          },
        },
        danger: {
          classes: {
            wrapper: 'bg-red-100 border-red-500',
            body: 'text-red-700',
            close: 'text-red-700 hover:text-red-500 hover:bg-red-200',
          },
        },
      },
    },
  
    TButton: {
      fixedClasses: 'focus:ring-2 focus:ring-offset-2 text-sm font-semibold leading-none focus:outline-none border rounded px-4 py-2',
      classes: 'focus:ring-indigo-700 text-white bg-indigo-700 hover:bg-indigo-600 disabled:bg-gray-400 disabled:cursor-not-allowed',
      variants: {
        primary: {
          classes: 'focus:ring-indigo-700 text-white bg-indigo-700 hover:bg-indigo-600 disabled:bg-gray-400 disabled:cursor-not-allowed',
        },
        secondary: {
          classes: 'focus:ring-gray-100 text-white bg-gray-100 hover:bg-gray-100',
        },
        action: {
          classes: 'ml-3',
        },
      },
    },
  
    TCard: {
      fixedClasses: {
        header: 'border-b border-gray-100 p-3 rounded-t',
      },
      classes: {
        wrapper: 'w-full rounded shadow bg-white',
        body: 'p-3 flex-1',
        header: 'focus:outline-none text-lg leading-5 text-gray-800',
      },
    },
  
    TInput: {
      classes: 'w-full p-3 mt-3 bg-gray-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-medium leading-none text-gray-800',
    },
  
    TInputGroup: {
      classes: {
        wrapper: 'mb-2',
        label: 'pb-2 text-sm font-bold text-gray-800',
        body: '',
        feedback: 'text-sm text-red-500',
        description: 'text-gray-400',
      },
    },
  
    TModal: {
      fixedClasses: {
        overlay: 'z-40  overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-70',
        wrapper: 'max-w-5xl',
        header: 'text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4',
      },
      classes: {
        wrapper: 'relative z-50 px-3 py-12 mx-auto max-w-5xl',
      },
    },
  
    TSelect: {
      classes: 'w-full p-3 mt-3 bg-gray-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-medium leading-none text-gray-800',
    },
  
    TTag: {
      variants: {
        gray: { class: 'text-sm font-semibold leading-none border rounded px-4 py-2 text-white bg-gray-300' },
        primary: { class: 'text-sm font-semibold leading-none border rounded px-4 py-2 text-white bg-indigo-500' },
      },
    },
  
    TTextarea: {
      classes: 'w-full p-3 mt-3 bg-gray-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-medium leading-none text-gray-800',
    },
  
    TRichSelect: {
      fixedClasses: {
        wrapper: 'mt-3',
        selectButton: 'w-full p-3 mt-3 bg-gray-100 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-sm font-medium leading-none text-gray-800',
      },
    },
  }

  export default variantConfiguration